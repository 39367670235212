import React, { Fragment, useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import ReactSearchBox from "react-search-box";

import {
  getAllVehiculos,
  getModelos,
  getFamilias,
  getProductos,
} from "../repository";
import CatalogueProductItem from "../components/CatalogueProductItem";

const SearchByVehicle = () => {
  const [status, setStatus] = useState(null);
  const [idVehiculo, setIdVehiculo] = useState(0);
  const [idModelo, setIdModelo] = useState(0);
  const [idFamilia, setIdFamilia] = useState(-1);
  const [vehiculos, setVehiculos] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(0);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
        email: "Correo invalido",
      },
    })
  );

  useEffect(() => {
    obtieneVehiculos();
    validator.current.hideMessages();
  }, []);

  const obtieneVehiculos = () => {
    getAllVehiculos()
      .then((response) => {
        if (response.status === "success") {
          setVehiculos(response.result);
          //console.log(response.result);
        } else {
          Swal(
            "Error obtener la información [Vehiculos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Vehiculos]", err, "error")
      );
  };

  const handleSearchBoxChange = (record) => {
    setIdModelo(0);
    setModelos([]);
    setIdFamilia(-1);
    setFamilias([]);
    setProductos([]);
    setStatus(null);
  };

  const handleSearchBoxSelect = (record) => {
    setIdVehiculo(record.key);
    setIdModelo(0);
    setModelos([]);
    setIdFamilia(-1);
    setFamilias([]);
    setProductos([]);
    setStatus(null);
    obtieneModelos(record.key);
    obtieneFamilias(0, 0);
  };

  const handleComboChange = (event) => {
    if (event.target.name === "idModelo") {
      setIdFamilia(-1);
      setFamilias([]);
      setProductos([]);
      setStatus(null);
      obtieneFamilias(idVehiculo, event.target.value);
      setIdModelo(event.target.value);
    }
    if (event.target.name === "idFamilia") {
      setProductos([]);
      setStatus(null);
      setIdFamilia(event.target.value);
    }
  };

  const obtieneModelos = (idVehiculo) => {
    getModelos(idVehiculo)
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.Modelo, text: item.Modelo };
          });
          setModelos(
            [
              { value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items)
          );
        } else {
          Swal(
            "Error obtener la información [Modelos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Modelos]", err, "error")
      );
  };

  const obtieneFamilias = (idVehiculo, idModelo) => {
    getFamilias(idVehiculo, idModelo)
      .then((response) => {
        if (response.status === "success") {
          var items = {};

          if (idVehiculo > 0 && idModelo > 0)
            items = response.result.map((item) => {
              return { value: item.idFamilia, text: item.Familia };
            });

          setFamilias(
            [
              { value: -1, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items)
          );
        } else {
          Swal(
            "Error obtener la información [Familias]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Familias]", err, "error")
      );
  };

  //Search Products
  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);
    if (validator.current.allValid()) {
      getProductos(idVehiculo, idFamilia, idModelo)
        .then((response) => {
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);
          } else {
            setLoading(0);
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          setLoading(0);
          Swal("Error obtener la información [Productos]", err, "error");
        });
    } else {
      validator.current.showMessages();
      setLoading(0);
    }
  };

  return (
    <div className="mt-3">
      <form onSubmit={search}>
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-3 form-group">
              Vehículo:
              <ReactSearchBox
                className="form-control"
                name="idVehiculo"
                key="idVehiculo"
                placeholder="Capture el Vehículo"
                value=""
                data={vehiculos}
                onSelect={handleSearchBoxSelect}
                onChange={handleSearchBoxChange}
                inputBoxFontSize="16"
              />
              {validator.current.message(
                "idVehiculo",
                idVehiculo,
                "required|alpha_num"
              )}
            </div>
            <div className="col-md-3 mb-3 form-group ">
              Modelo:
              <select
                name="idModelo"
                key="idModelo"
                className="form-control"
                onChange={(e) => {
                  handleComboChange(e);
                }}
              >
                {modelos.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {validator.current.message(
                "idModelo",
                idModelo,
                "required|numeric|min:1,num"
              )}
            </div>
            <div className="col-md-3 form-group">
              Familia:
              <select
                name="idFamilia"
                key="idFamilia"
                className="form-control"
                onChange={(e) => {
                  handleComboChange(e);
                }}
              >
                {familias.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {validator.current.message(
                "idFamilia",
                idFamilia,
                "required|numeric|min:0,num"
              )}
            </div>
            <div className="col-md-3 form-group">
              <h6>&nbsp;</h6>
              <button
                className="btn btn-warning btn-block font-weight-normal"
                type="submit"
              >
                <i className="fas fa-search mr-1"></i>Buscar
              </button>
            </div>
          </div>
          {loading === 1 && (
            <div className="text-center">
              <p>Cargando...</p>
              <div
                className="spinner-border text-warning"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
          )}
          {status === "success" && (
            <Fragment>
              <div className="row mt-3">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <h6>
                    <span className="badge badge-warning ml-1 mr-1">
                      {productos.length}
                    </span>
                    <small>
                      <strong>Producto(s) encontrado(s)</strong>
                    </small>
                  </h6>
                  <div className="col-md-3"></div>
                </div>
              </div>
              <div className="row">
                {productos.map((item, index) => (
                  <CatalogueProductItem item={item} key={index} index={index} />
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </form>
    </div>
  );
};

export default SearchByVehicle;
