import React, { Fragment, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { isMobileOnly } from "react-device-detect";
import Swal from "sweetalert";

import { getProductosByDescripcion } from "../repository";
import CatalogoProductItem from "../components/CatalogueProductItem";

const SearchByProduct = () => {
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(0);
  const [productos, setProductos] = useState([]);
  const [status, setStatus] = useState(null);
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  const changeState = (event) => {
    setDesc(event.target.value);
    setProductos([]);
    setStatus(null);
  };

  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);
    if (validator.current.allValid()) {
      getProductosByDescripcion(desc)
        .then((response) => {
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);
          } else {
            setLoading(0);
            Swal("Error obtener la información", response.message, "error");
          }
        })
        .catch((err) => {
          setLoading(0);
          Swal("Error obtener la información", err, "error");
        });
    } else {
      validator.current.showMessages();
      setLoading(0);
    }
  };  
  return (
    <div>
      <form onSubmit={(e) => search(e)}>
        <div className="container">
          <div className="row mt-2">
            <div className="col mt-2">¿Qué estás buscando?:</div>
            </div>
            <div className="row ">
            <div className="col mb-3">
              <input
                name="txtDesc"
                type="text"
                className="form-control"
                value={desc}
                autoComplete="off"
                placeholder={
                  isMobileOnly === true
                    ? "Ejemplos: tsuru, civic 2007..."
                    : "Ejemplos: tsuru, civic 2007, soporte sienna 2010..."
                }
                onChange={(e) => {
                  changeState(e);
                }}
                maxLength="50"
              />
              {validator.current.message("txtDesc", desc, "required|string")}
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-warning btn-block font-weight-normal"
                type="submit"
              >
                <i className="fas fa-search mr-1"></i>Buscar
              </button>
            </div>
          </div>
          {loading === 1 && (
            <div className="text-center">
              <p>Cargando...</p>
              <div
                className="spinner-border text-warning"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
          )}
          {status === "success" && (
            <Fragment>
              <div className="row mt-3">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <h6>
                    <span className="badge badge-warning ml-1 mr-1">
                      {productos.length}
                    </span>
                    <small>
                      <strong>Producto(s) encontrado(s)</strong>
                    </small>
                  </h6>
                  <div className="col-md-3"></div>
                </div>
              </div>
              <div className="row">
                {productos.map((item, index) => (
                  <CatalogoProductItem item={item} key={index} index={index} />
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </form>
    </div>
  );
};

export default SearchByProduct;
