import React, { useState, useEffect, Fragment } from "react";
import { obtieneFamiliaProductos } from "../repository";

import { NavLink } from "react-router-dom";

const Menu = () => {
  const [productosFamilia, setProductosFamilia] = useState([]);
  const [productosFamilia2, setProductosFamilia2] = useState([]);
  const [productosFamilia3, setProductosFamilia3] = useState([]);
  const [productosFamilia4, setProductosFamilia4] = useState([]);
  const [productosFamilia5, setProductosFamilia5] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(true);

  const obtieneProductosFam = (idFamilia) => {
    obtieneFamiliaProductos(idFamilia)
      .then((response) => {
        if (response.status === 200) {
          switch (idFamilia) {
            case 1:
              setProductosFamilia(response.data.result);
              break;
            case 2:
              setProductosFamilia2(response.data.result);
              break;
            case 3:
              setProductosFamilia3(response.data.result);
              break;
            case 4:
              setProductosFamilia4(response.data.result);
              break;
            case 5:
              setProductosFamilia5(response.data.result);
              break;
            default:
              break;
          }
        } else {
          alert("Error obtener la información");
          setProductosFamilia([]);
        }
      })
      .catch(
        (err) => alert("Error obtener la información" + err),
        setProductosFamilia([])
      );
  };

  useEffect(() => {
    obtieneProductosFam(1);
    obtieneProductosFam(2);
    obtieneProductosFam(3);
    obtieneProductosFam(4);
    obtieneProductosFam(5);
    setLoading(false);
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark fixed-top navbar-shrink"
      name="mainNav"
      id="mainNav"
    >
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="/">
          <img src="img/logos/govi-small.png" alt="" className="img-fluid" />
        </a>

        <button
          className="navbar-toggler navbar-toggler-right fluid"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menú
          <i className="fas fa-bars ml-2"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ml-auto">
            <li className="nav-item">
              <NavLink
                to="/Services"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                Servicios
              </NavLink>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                name="navbarDropdown"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Productos
              </a>
              <ul
                className="dropdown-menu "
                id="navBarDropDown2"
                name="navBarDropDown2"
                aria-labelledby="navbarDropdown"
              >
                <li className="dropdown-submenu">
                  {" "}
                  <a
                    href="/Products1"
                    id="navbarDropdownMenuSuspension"
                    className="dropdown-item dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Suspensión
                  </a>
                  <ul
                    className="dropdown-menu"
                    labelledby="navbarDropdownMenuSuspension"
                  >
                    {productosFamilia.map((item, index) => (
                      <Fragment>
                        <li>
                          <a class="dropdown-item" href="/Products1">
                            {item.Familia}
                          </a>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </li>

                <li>
                  <li className="dropdown-submenu">
                    {" "}
                    <a
                      href="/Products2"
                      id="navbarDropdownMenuTraccion"
                      className="dropdown-item dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Tracción delantera
                    </a>
                    <ul
                      className="dropdown-menu"
                      labelledby="navbarDropdownMenuTraccion"
                    >
                      {productosFamilia2.map((item, index) => (
                        <Fragment>
                          <li>
                            <a class="dropdown-item" href="/Products2">
                              {item.Familia}
                            </a>
                          </li>
                        </Fragment>
                      ))}
                    </ul>
                  </li>
                </li>
                <li className="dropdown-submenu">
                  {" "}
                  <a
                    href="/Products3"
                    id="navbarDropdownMenuDireccion"
                    className="dropdown-item dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dirreción
                  </a>
                  <ul
                    className="dropdown-menu"
                    labelledby="navbarDropdownMenuDireccion"
                  >
                    {productosFamilia3.map((item, index) => (
                      <Fragment>
                        <li>
                          <a class="dropdown-item" href="/Products3">
                            {item.Familia}
                          </a>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </li>

                <li className="dropdown-submenu">
                  {" "}
                  <a
                    href="/Products4"
                    id="navbarDropdownMenuSoportes"
                    className="dropdown-item dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Soportes
                  </a>
                  <ul
                    className="dropdown-menu"
                    labelledby="navbarDropdownMenuSoportes"
                  >
                    {productosFamilia4.map((item, index) => (
                      <Fragment>
                        <li>
                          <a class="dropdown-item" href="/Products4">
                            {item.Familia}
                          </a>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </li>

                <li className="dropdown-submenu">
                  {" "}
                  <a
                    href="/Products5"
                    id="navbarDropdownMenuSoportes"
                    className="dropdown-item dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Amortiguadores
                  </a>
                  <ul
                    className="dropdown-menu"
                    labelledby="navbarDropdownMenuSoportes"
                  >
                    {productosFamilia5.map((item, index) => (
                      <Fragment>
                        <li>
                          <a class="dropdown-item" href="/Products5">
                            {item.Familia}
                          </a>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </li>
              </ul>
            </li>
            {/*


            <li className="nav-item">
              <NavLink
                to="/Products"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                Productos
              </NavLink>
            </li> 
            */}

            <li className="nav-item">
              <NavLink
                to="/Catalogue"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Encuentra tu Producto              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/About"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Nosotros
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Locations"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Ubicaciones
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Contact"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Contacto
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
