import React, { useState, useEffect, Fragment } from "react";

import ProductItems from "../components/ProductItems";
import { getFamiliasImg, getFamiliasImgGOVI } from "../repository";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import global from "../global";

const Products4 = () => {
  const [familias, setFamilias] = useState([]);

  const obtieneFamiliasInicio = () => {
    getFamiliasImgGOVI()
      .then((response) => {
        if (response.status === 200) {
          setFamilias(response.data.result);
         
        } else {
          alert("Error obtener la información del catalogo de  [Ubicaciones]");
          setFamilias([]);
        }
      })
      .catch(
        (err) => alert("Error obtener la información [Ubicaciones] " + err),
        setFamilias([])
      );
  };

  useEffect(() => {
    obtieneFamiliasInicio();
  }, []);

  return (
    <Fragment>
      <Menu />
      <section className="page-section" id="products">
        <div className="container" name="container">
          <div className="col-lg-12 text-center" name="divcol">
            <h2 className="section-heading text-uppercase">Productos</h2>
            <h3 className="section-subheading text-muted mb-5">
              {global.leyendaProductos}
            </h3>
          </div>

          <div className="container">
            <div className="row mt-3">
              <div className="col-md-12">
              <Fragment> 
                              <div className="card" id={"nav-" + "1" }>
                      <div className="card-header text-center">
                      <h5>
                        DIRECCIÓN
                      {/*  {items.strDescripcion.toUpperCase()} */}
                        </h5>
                      </div>
                  <div className="card-body">
                  <ProductItems key={4} idFamilia={4} />
                          </div>
                </div>
             
              </Fragment>
             
            
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Products4;
