import React, { Fragment, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import { getProductosByCode } from "../repository";
import CatalogueProductItem from "../components/CatalogueProductItem";

const SearchByCode = () => {
  const codigoRef = React.createRef();
  const [equivalencias2, setEquivalencias2] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [loading, setLoading] = useState(0);
  const [productos, setProductos] = useState([]);
  const [status, setStatus] = useState(null);
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
        email: "Correo invalido",
      },
    })
  );

  const toggleEquivalencias2 = () => {
    setEquivalencias2(!equivalencias2);
  };

  const changeState = (event) => {
    setCodigo(event.target.value);
    setProductos([]);
    setStatus(null);
  };

  //Search Products
  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);
    if (validator.current.allValid()) {
      getProductosByCode(codigo, equivalencias2 === true ? 1 : 0)
        .then((response) => {
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);
          } else {
            setLoading(0);
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          setLoading(0);
          Swal("Error obtener la información [Productos]", err, "error");
        });
    } else {
      validator.current.showMessages();
      setLoading(0);
    }
  };
  return (
    <div>
      <form onSubmit={search}>
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-1 mt-2">Código:</div>
            <div className="col-md-3">
              <input
                name="txtCodigo"
                type="text"
                className="form-control"
                defaultValue=""
                ref={codigoRef}
                autoComplete="off"
                placeholder="Capture el Código"
                onChange={(e) => {
                  changeState(e);
                }}
                maxLength="20"
              />
              {validator.current.message(
                "txtCodigo",
                codigo,
                "required|alpha_num_dash"
              )}
            </div>
            <div className="col-md-3 custom-control custom-checkbox mt-2 ml-3 mb-3">
              <input
                type="checkbox"
                className="custom-control-input"
                name="chkEquivalencias"
                id="chkEquivalencias2"
                onChange={toggleEquivalencias2}
                checked={equivalencias2}
              />
              <label
                className="custom-control-label"
                htmlFor="chkEquivalencias2"
              >
                Procesar Equivalencias
              </label>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-warning btn-block font-weight-normal"
                type="submit"
              >
                <i className="fas fa-search mr-1"></i>Buscar
              </button>
            </div>
          </div>
          {loading === 1 && (
            <div className="text-center">
              <p>Cargando...</p>
              <div
                className="spinner-border text-warning"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
          )}
          {status === "success" && (
            <Fragment>
              <div className="row mt-3">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <h6>
                    <span className="badge badge-warning ml-1 mr-1">
                      {productos.length}
                    </span>
                    <small>
                      <strong>Producto(s) encontrado(s)</strong>
                    </small>
                  </h6>
                  <div className="col-md-3"></div>
                </div>
              </div>
              <div className="row content-align-center">
                {productos.map((item, index) => (
                  <CatalogueProductItem item={item} key={index} index={index} />
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </form>
    </div>
  );
};

export default SearchByCode;
