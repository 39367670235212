import { Fragment } from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Global from "../global";

const About = () => {
  return (
    <Fragment>
      <Menu />
      <section className="page-section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Nosotros</h2>
              <h3 className="section-subheading text-muted">
                {Global.leyendaNosotros}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4 className="subheading">
                        GOVI, un renacer en Monterrey
                      </h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        En 1980, el Grupo GOVI fué fundado por mi padre, el Sr. Don
                        Roberto González Villarreal.
                        <br />
                        <br />
                        Posteriormente decidí enfocar todo el esfuerzo del
                        negocio en la comercialización de partes para la {" "}
                        suspensión automotríz con el sueño y la necesidad de que
                        los clientes encontraran en una sola parte todos los
                        productos; al haber cumplido este sueño, logramos ser la
                        primera empresa especializada en el noreste del país
                        dedicada exclusivamente a la venta de partes de
                        suspensión.
                        <br />
                        <br />
                        Contamos con un equipo de profesionales responsables y
                        leales, que tienen la consigna de atender y asesorar
                        a nuestros clientes de manera rápida y sin errores.
                        <br />
                        <br />
                        Juntos crecemos cada día, sirviendo a favor de las
                        necesidades de surtido, calidad y rentabilidad,
                        obligándonos a ser cada vez mejores.
                        <br />
                        <br />
                        Grupo GOVI es dueño de la marca <b>GROB SUSPENSIÓN
                        AUTOMOTRIZ</b> y <b>GROB COMFORT AMORTIGUADORES</b>, que
                        nacieron con el objetivo de ofrecer todos los productos
                        en una sola calidad y en un solo envase, cubriendo los
                        estándares de calidad-precio.
                        <br />
                        Nuestros clientes nos dan retroalimentación de las
                        necesidades de calidad y éstas son atendidas y resueltas
                        de inmediato por nuestras fábricas. Esto le puede dar al
                        cliente la seguridad de que en todo momento va a encontrar
                        en nuestra marca calidad.
                        <br />
                        <br />
                        Ahora, con la nueva sangre del negocio,{" "}
                        <b>Antonio González</b> y sus colaboradores, cada año
                        hacen una revisión de los vehículos ingresados al
                        mercado y de las necesidades de los mismos, para
                        actualizar los nuevos productos sin descuidar los ya
                        existentes, esto garantiza siempre una constante y
                        actualizada existencia.
                        <br />
                        Sostenemos constantes juntas con los diferentes
                        departamentos para analizar y encontrar procesos
                        fáciles, rápidos y seguros, con la finalidad de ofrecer a
                        nuestros clientes un excelente servicio.
                      </p>
                      <br />
                      <p>
                        <strong>Edgar González Lozano</strong>
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded mt-4"
                      src="img/logos/govi-small.png"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Misión</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        Ser líder de autopartes de suspensión, a través de
                        ofrecer calidad, existencia y rapidez.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded mt-4"
                      src="img/logos/govi-small.png"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Visión</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        Contamos con políticas, procesos definidos y
                        automatizados, para llegar a ser una empresa
                        autoadministrada. Personal capacitado en sus áreas, en
                        conocimientos del producto, habilidades y actitudes, con
                        enfoque del servicio al cliente.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/4.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Valores</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        Responsabilidad / Honestidad / Lealtad
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <h4>Se parte de nuestra historia!</h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default About;
