import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./screens/Home";
import Services from "./screens/Services";
import Products from "./screens/Products";
import Catalogue from "./screens/Catalogue";
import About from "./screens/About";
import Locations from "./screens/Locations";
import Contact from "./screens/Contact";
import Products1 from "./screens/Products1"
import Products2 from "./screens/Products2"
import Products3 from "./screens/Products3"
import Products4 from "./screens/Products4"
import Products5 from "./screens/Products5"

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Catalogue} />
          <Route exact path="/Services" component={Services} />
         
          <Route exact path="/Products1" component={Products1} />
          <Route exact path="/Products2" component={Products2} />
          <Route exact path="/Products3" component={Products3} />
          <Route exact path="/Products4" component={Products4} />
          <Route exact path="/Products5" component={Products5} />
         
          <Route exact path="/Catalogue" component={Catalogue} />
          <Route exact path="/About" component={About} />
          <Route exact path="/Locations" component={Locations} />
          <Route exact path="/Contact" component={Contact} />
          <Route exact path="/Home" component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
